// const signButton = document.querySelectorAll("form button");
const showSolutionButton = document.querySelector("#solution-button");
const codeBlock = document.querySelector("pre");
// const email = document.querySelectorAll("form input");

// signButton.forEach((btn) => {
//   btn.addEventListener("click", signIn);
// });
showSolutionButton.addEventListener("click", toggleShowSolution);

// function signIn(event) {
//   event.preventDefault();
//   if(validateInput()) {
//     window.location.href = "verify.html";
//   }
// }
// function validateInput() {
//   email.forEach(input => {
//     input.classList.remove("bg-red-500");
//     if(!input.value) {
//       input.classList.add("bg-red-500");
//     }
//   });
//   const validate = email[0].value || email[1].value ? true : false;
//   return validate;
// };
function toggleShowSolution() {
  codeBlock.classList.toggle("hidden");
  showSolutionButton.innerText = showSolutionButton.innerText === "Ver solución" ? "Ocultar solución" : "Ver solución"
};